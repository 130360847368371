import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';

import defaultRequestErrorHandler from 'lux/requests/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import DefaultVerification from 'src/components/Verification/Verification';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import formatAnalyticErrors from 'src/utils/analytics/formatAnalyticErrors';
import fetcher from 'src/utils/fetcher';

import { loginBy } from 'src/components/ApplicantSignInSignUpFlow/SignInStep/SignInForm';

const VerificationFormStep = ({ Verification = DefaultVerification, isSignupPage, next, ...props }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const { login, authType, backurl } = useSelector(({ otp }) => otp);
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);
    const vrResponseHash = useSelector(({ vrSignupLogin }) => vrSignupLogin.vrResponseHash);

    const onShownAnalytics = useCallback(
        (elementRef) => {
            if (elementRef.current) {
                Analytics.sendHHEventElementShown(elementRef.current, {
                    elementName: 'code_confirmation',
                    vacancyId,
                    authType: loginBy[authType] || 'unknown',
                    isSignupPage,
                });
            }
        },
        [authType, isSignupPage, vacancyId]
    );

    const onSubmitAnalytics = (errorCode) => {
        Analytics.sendHHEvent('form_submit', {
            formName: 'code_confirmation',
            vacancyId,
            authType: loginBy[authType] || 'unknown',
            errors: errorCode ? formatAnalyticErrors('code_verification', errorCode) : null,
            isSignupPage,
        });
    };

    const onSubmit = async (setIsSending, setVerification, code) => {
        let response;
        setIsSending(true);

        try {
            response = await fetcher.postFormData('/account/login/by_code', {
                username: login,
                code,
                remember: true,
                accountType: 'APPLICANT',
                isApplicantSignup: isSignupPage,
                backurl,
            });
        } catch (error) {
            setIsSending(false);
            const errorCode = error?.response?.data?.error?.key;
            const isMultiAccountCreation = error?.response?.data?.isMultiAccountCreation;

            if (errorCode === 'EMPLOYER_NOT_ALLOWED' && isMultiAccountCreation) {
                try {
                    await fetcher.post('/multiaccount_grouping/reset_employer_password', {
                        code,
                    });
                } catch {
                    onSubmitAnalytics(errorCode);
                    dispatch(push(error.response.data.employerLoginURL));
                    return;
                }

                onSubmitAnalytics(errorCode);
                next(code, isMultiAccountCreation);
                return;
            }

            if (errorCode === 'EMPLOYER_NOT_ALLOWED' || errorCode === 'UNEXPECTED_USER_TYPE') {
                dispatch(push(error.response.data.employerLoginURL));
                onSubmitAnalytics(errorCode);
                return;
            }

            if (errorCode === 'UNEXPECTED_USER_TYPE' || errorCode === 'ANONYMOUS_ONLY') {
                dispatch(push(error.response.data.loginURL));
                onSubmitAnalytics(errorCode);
                return;
            }

            if (errorCode === 'ACCOUNT_NOT_FOUND') {
                onSubmitAnalytics();
                next(code, isMultiAccountCreation);
                return;
            }

            onSubmitAnalytics('SERVER_ERROR');
            defaultRequestErrorHandler(error, addNotification);
            return;
        }

        const errorCode = response?.data?.success ? null : response?.data?.verification?.key;
        onSubmitAnalytics(errorCode);
        setIsSending(false);

        if (response?.data?.success) {
            if (vrResponseHash) {
                dispatch(push(`/vr_signup_login?vrResponseHash=${vrResponseHash}`));
                return;
            }
            window.location.assign(backurl);
            return;
        }

        setVerification(response?.data?.verification);
    };

    return (
        <Verification
            onShownAnalytics={onShownAnalytics}
            authType={authType}
            login={login}
            onSubmit={onSubmit}
            {...props}
        />
    );
};

VerificationFormStep.propTypes = {
    trls: PropTypes.object,
    login: PropTypes.string,
    next: PropTypes.func,
    isSignupPage: PropTypes.bool,
    Verification: PropTypes.elementType,
};

export default translation(VerificationFormStep);
